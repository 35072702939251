import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { SubMenu } from "./submenu";

import { UserService } from "../../service/user.service";

import { ConvertDate, HelpStatus, ConvertDate2, ConverMoeny } from "../../utility/help";
import { runInThisContext } from "vm";
import { BalanceService } from "../../service/balance.service";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  detail = "detail",
}

const CustomTableCell = styled(TableCell)`
  color: white;
  padding: 4px;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});


interface Props {
  handleClose: () => any;
  handleActive: (active:string) => any;

}

interface State {
    balances: any;
  mode: string;

  title: string;
  contents: string;
  detail: any;
}

export class InOut extends Component<Props, State> {
    balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balances: [], mode: helpView.view, title: "", contents: "" , detail : {}};
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.balanceService.get_deposit_and_withdraw().then((data: any) => {
      if (data.status === "success") {
        this.setState({ balances: data.balances });
      }
    });
  };


  render() {
    let balances = this.state.balances;

    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "#000",
          border: "none",
          width: "none",
        }}
        onClose={() => {}}
      >
        {(close) => (
          <div  id="fade_2"  className="slideDown popup_none popup_content" data-popup-initialized="true" aria-hidden="false" role="dialog" style={{opacity: 1, visibility: 'visible', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle', overflowY : 'auto', height : '100%'}}>
          <div className="popup_wrap">
            <div className="close_box">
              <a onClick={()=>{this.props.handleClose()}} className="fade_1_close"><img src="/web/images/popup_close.png" /></a>
            </div>
            <div className="popupbox">

            {this.props.handleActive != null && <SubMenu handleActive={(active : string)=>{this.props.handleActive(active)}}></SubMenu> }

  
        
              <div id="popuptab_cont8" className="popuptab_cont popupvis_hidden">
        <div className="title1">
           공지사항
        </div>
  
          </div>
              <div id="popuptab_cont9" className="popuptab_cont">
        
        <div className="title1">
           고객센터
        </div>
          
      
              <div className="contents_in">
                <div className="con_box00">
                  <table   style={{width : '100%'}}>
                  <tbody>
                    <tr>
                      <td className="list_title1">타입</td>
                      <td className="list_title1">금액</td>
                      <td className="list_title1" style={{width : '10%'}}>상태</td>
                      <td className="list_title1" style={{width : '10%'}}>작성일</td>
                    </tr>

                    {balances.map((info: any) => {
                      return (
                      <tr onClick={()=>{}}>
                        <td className="list_title1" style={{width: '24.999%'}}>
                            {info.type === 'deposit' && <span style={{ color: '#03a9f4'}}>충전</span>}
                            {info.type === 'withdraw' && <span style={{ color: '#f44336'}}>환전</span>}
                        </td>

                        <td className="list_title1" style={{width: '24.999%'}}>{ConverMoeny(info.balance)}</td>


                            <td className="list_title1" style={{width: '24.999%'}}>
                                {info.status === 'already' && <span style={{ color: 'green'}}>완료</span>}
                                {info.status === 'cansel' && <span style={{ color: '#f44336'}}>취소</span>}
                                {info.status !== 'already' && info.status !== 'cansel'   && <span  style={{ color: '#ff9800'}}>대기</span>}
                            </td>

                
                            <td className="list_title1" style={{width: '24.999%'}}>{ConvertDate(info.regdate)}</td>
                      </tr>
                      )
                    })}

                  </tbody></table>
                </div>

           
              </div>
         
        
        </div>
     
            </div>
          </div>
        </div>
        
        )}
      </Popup>
    );
  }
}
